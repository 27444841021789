import React, {Fragment, useEffect} from 'react'

import {useLang} from '@_metronic/i18n/Metronici18n'

import useCatalogsStore, {CatalogCodeName} from '@app/store/useCatalogStore'
import useIsPremium from '@app/hooks/useIsPremium'

import CatalogField from './components/CatalogField'

import {SimValuesType} from '../EditSimDrawerContent'

type EditSimCatalogParamsProps = {
  allSimValues: SimValuesType
  values: any
  visibleCodeNames?: string[]
  hiddenCodeNames?: string[]
  onChange: (updatedValues: any) => void
  onAllValuesChange: (updatedValues: SimValuesType) => void
}
const EditSimCatalogParams = ({
  allSimValues,
  values = {},
  visibleCodeNames,
  hiddenCodeNames,
  onChange,
  onAllValuesChange,
}: EditSimCatalogParamsProps) => {
  // console.log('### EditSimCatalogParams', values, allSimValues.type)
  const {loadCatalogs, catalogs} = useCatalogsStore()
  // console.log('### catalogs', catalogs)

  useEffect(() => {
    loadCatalogs()
  }, [])

  const catalogsFilteredByType = catalogs
    ?.filter((catalog) => {
      if (visibleCodeNames) {
        return visibleCodeNames.includes(catalog.code_name)
      }

      if (hiddenCodeNames) {
        return !hiddenCodeNames.includes(catalog.code_name)
      }
    })
    .filter((catalog) => {
      if (allSimValues.type === 0) {
        return catalog.code_name !== CatalogCodeName.animal_personality_traits
      }

      return catalog.code_name !== CatalogCodeName.character_traits
    })

  // console.log('### catalogsFilteredByType', allSimValues.type, catalogsFilteredByType)

  const language = useLang()
  const isPremium = useIsPremium()

  return (
    <>
      {catalogsFilteredByType?.map((catalog) => {
        // console.log('### catalogsFilteredByType.map', {values, code_name: catalog.code_name})
        const fieldValues = values[catalog.code_name] || []
        const catalogName = catalog['name_' + language]
          ? catalog['name_' + language]
          : catalog.name_en

        return (
          <Fragment key={catalog.code_name}>
            {typeof allSimValues[catalog.code_name] !== 'undefined' ? (
              <div className='mb-10'>
                <label className=' form-label'>{catalogName}</label>
                <input
                  type='text'
                  value={allSimValues[catalog.code_name]}
                  className='form-control'
                  placeholder=''
                  onChange={(e) =>
                    onAllValuesChange({...allSimValues, [catalog.code_name]: e.currentTarget.value})
                  }
                />
              </div>
            ) : null}
            <CatalogField
              key={catalog.code_name}
              disabled={!isPremium}
              catalog={catalog}
              values={fieldValues}
              onChange={(value) => {
                onChange({...values, [catalog.code_name]: value})
              }}
              onDeleteOutdatedValue={() => {
                const allSimValuesClone = {...allSimValues}
                onAllValuesChange(allSimValuesClone)
              }}
            />
          </Fragment>
        )
      })}
    </>
  )
}

export default React.memo(EditSimCatalogParams)
